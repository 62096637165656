.CategoryPage {
}

.CategoryPage .CategoryItem {
    background-color: transparent;
}

.CategoryPage-Content {
    padding-top: 84px;
}

.CategoryPage-Content:before {
    position: absolute;
    display: block;
    content: '';
    height: 216px;
    width: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: 100% auto;
}

.CategoryPage-Content_type_pressa.CategoryPage-Content:before {
    background-image: url("../Images/press.jpg");
}

.CategoryPage-Content_type_sport.CategoryPage-Content:before {
    background-image: url("../Images/sport.jpg");
}

.CategoryPage-Content_type_avto.CategoryPage-Content:before {
    background-image: url("../Images/auto.jpg");
}

.CategoryPage-Content_type_stil.CategoryPage-Content:before {
    background-image: url("../Images/style.jpg");
}

.CategoryPage-Content_type_gadzheti.CategoryPage-Content:before {
    background-image: url("../Images/gadgets.jpg");
}

.CategoryPage-Content_type_otdikh.CategoryPage-Content:before {
    background-image: url("../Images/rest.jpg");
}

.CategoryPage-Content_type_kosmos.CategoryPage-Content:before {
    background-image: url("../Images/space.jpg");
}

.CategoryPage-Content_type_pitanie.CategoryPage-Content:before {
    background-image: url("../Images/food.jpg");
}

@media (min-width: 720px) {
    .CategoryPage-Content:before {
        background-position: 50% -130px;
    }
}

@media (min-width: 1024px) {
    .CategoryPage-Content:before {
        background-position: 50% -650px;
    }
}
