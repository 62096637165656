.ContentListPartial {
    display: flex;
    justify-content: center;
}

.ContentListPartial-ItemsWrapper {
    display: grid;
    flex-direction: column;
    justify-content: center;
    grid-gap: 24px;
    padding: 0 24px;
    width: 100%;
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(8, auto);
}

.ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

@media (min-width: 720px) {
    .ContentListPartial-ItemsWrapper {
        grid-template-columns: repeat(2, calc(50% - 12px));
        grid-template-rows: repeat(4, auto);
    }
}

@media (min-width: 1024px) {
    .ContentListPartial-ItemsWrapper {
        grid-template-columns: repeat(4, calc(25% - 18px));
        grid-template-rows: repeat(2, auto);
    }
}

/*@media (max-width: 1200px) {*/
/*    .ContentListPartial-ItemsWrapper {*/
/*        grid-template-columns: repeat(2, minmax(200px, 348px));*/
/*    }*/

/*    .ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {*/
/*        grid-column: span 1;*/
/*        grid-row: span 2;*/
/*        margin-bottom: 0;*/
/*        height: auto;*/
/*    }*/
/*}*/



/*@media (min-width: 600px) {*/
/*    .ContentListPartial-ItemsWrapper {*/
/*        grid-template-columns: repeat(4, minmax(200px, 320px));*/
/*        grid-template-rows: repeat(3, auto);*/
/*    }*/
/*}*/
