@font-face {
  font-family: "Averta Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Averta Cyrillic Regular"), url(./fonts/Averta-Cyrillic-Regular.otf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Averta Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Averta Cyrillic Bold"), url(./fonts/Averta-Cyrillic-Bold.otf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Averta Bold Italic";
  font-style: normal;
  font-weight: 700;
  src: local("Averta Cyrillic Bold Italic"), url(./fonts/Averta-Cyrillic-Bold-Italic.otf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Averta Black";
  font-style: normal;
  font-weight: 900;
  src: local("Averta Cyrillic Black"), url(./fonts/Averta-Cyrillic-Black.otf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Averta Light Italic";
  font-style: normal;
  font-weight: 300;
  src: local("Averta Cyrillic Light Italic"), url(./fonts/Averta-Cyrillic-Light-Italic.otf) format("opentype");
  font-display: swap;
}

* {
  -webkit-tap-highlight-color: transparent !important;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  padding: 0;
  font-family: "Averta Regular", Arial, sans-serif;
  line-height: 1;
  overflow-x: hidden;
  min-width: 320px;
  color: #4c4c4e;
  margin: 0 auto;
  position: relative;
}

button,
a {
  cursor: pointer;
  display: block;
}

a {
  text-decoration: none;
}


/*swiper*/
.swiper-button-next,
.swiper-button-prev {
  background-image: url('./Components/Icons/NextArrow.svg');
  width: 60px;
  height: 60px;
  z-index: 999;
  background-position: center;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  top: 120px;
}

.swiper-scrollbar {
  height: 4px !important;
  max-width: 1400px !important;
  position: relative !important;
  left: 0 !important;
  background:rgba(255, 255, 255, 0.2);
  margin-top: 30px;
}

.swiper-button-prev {
  left: 80px;
  transform: scale(-1, -1);
}
.swiper-button-next{
  right: 80px;
  transform: scale(1, 1);
}
