.ContentPage {
    display: flex;
    justify-content: center;
    padding: 0;
}

.ContentPage_page_video {
    background-image: linear-gradient(180deg, #0a0a0a 0%, #333 100%);;
}

.ContentPage-Content {
    margin-top: 84px;
    padding-top: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
}

.ContentPage-Content_type_video {
    background-image: linear-gradient(180deg, #0a0a0a 0%, #333 100%);
}

.ContentPage-Wrapper {
    width: 100%;
    max-width: 1272px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.ContentPage-CategoryLink-Btn {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
}

.ContentPage-AdditionalBar_theme_dark {
    background-image: linear-gradient(180deg, #0a0a0a 0%, #333 100%);
}

.ContentPage-Video {
    max-width: 100%;
    margin-bottom: 20px;
}

.ContentPage-Video[poster] {
    object-fit: cover;
}

.ContentPage-ContentAdditionalWrapper {
    display: flex;
    flex-direction: column;
    margin: 0 24px;
}

.ContentPage-ContentAdditionalTitle {
    font-family: "Averta Regular", sans-serif;
    font-weight: 700;
    color: #000;
    text-align: left;
    margin: 24px 0;
}
.ContentPage-AdditionalBar_theme_dark .ContentPage-ContentAdditionalTitle {
    color: #fff;
}

.ContentPage-Title {
    position: relative;
    margin-left: 11px;
    font-family: "Averta Regular", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #000;
    display: flex;
    flex-direction: row;
}

.ContentPage-Image {
    border-radius: 24px;
    width: 100%;
    aspect-ratio: 1 / 1;
    margin-bottom: 24px;
}

.ContentPage-ContentName {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
    color: #000;
    margin-bottom: 24px;
}

.ContentPage-Title:before {
    display: none;
}

.ContentPage-MainBar {
    width: 100%;
}

.ContentPage-ContentWrap{
    box-shadow: none;
    margin: 0;
    padding: 0 24px;
}

.ContentPage-CategoryLink {
    height: 48px;
    width: calc(100% - 48px);
    color: #969696;
    font-size: 16px;
    font-weight: 700;
    line-height: 48px;
    border-radius: 12px;
    border: 2px solid #969696;
    margin: 12px auto 24px;
    padding-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContentPage-CategoryLink img {
    transform: rotate(180deg);
    filter: brightness(0.6);
    margin-top: 3px;
    margin-right: 12px;
}

.ContentPage-AdditionalBar {
    margin-top: 0;
    max-width: 100%;
    justify-content: center;
    display: flex;
    border-top: 1px solid #969696;
}

.ContentPage-AdditionalBar.ContentPage-AdditionalBar_theme_dark {
    border-top: none;
}

@media (max-width: 374px) {
    .ContentPage-CategoryLink {
        font-size: 13px;
    }
}

@media (max-width: 800px) {
    .ContentPage-Wrapper {
        width: 100%;
        flex-direction: column;
    }

    .ContentPage-Title {
        margin-left: -8px;
    }

    .ContentPage-ContentWrap {
        width: auto;
    }
}


@media (min-width: 720px) {
    .ContentPage-Image {
        width: calc(100% - 200px);
        box-shadow: 50px 0 0 0 rgba(255, 169, 0, 0.6), 100px 0 0 0 rgba(255, 169, 0, 0.4), 150px 0 0 0 rgba(255, 169, 0, 0.2), 200px 0 0 0 rgba(255, 169, 0, 0.1);
    }

    .ContentPage_page_text .ContentPage-TitleWrap {
        position: relative;
    }

    .ContentPage_page_text .ContentPage-ContentName {
        color: #EEEEEE;
        position: absolute;
        bottom: 24px;
        left: 24px;
        width: calc(100% - 248px);
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 0;
    }
}

@media (min-width: 1024px) {
    .ContentPage_page_text {
        background-color: #EEEEEE;
    }
    .ContentPage_page_text .ContentPage-MainBar {
        background-color: #fff;
        margin-right: 96px;
        margin-bottom: 24px;
        padding: 24px;
        border-radius: 24px;
    }

    .ContentPage-Content_type_video .ContentPage-Wrapper {
        flex-direction: column;
    }

    .ContentPage-ContentWrap {
        padding: 0;
    }

    .ContentPage-CategoryLink {
        width: 300px;
        margin-left: 0;

    }

    .ContentPage-Video {
        margin-bottom: 0;
    }

    .ContentPage-ContentTextWrap {
        border-radius: 0;
        width: auto;
        position: relative;
        background-color: transparent;
        box-shadow: none;
    }

    .ContentPage-AdditionalBar {
        border: none;
    }

    .ContentPage-AdditionalBar_theme_dark {
        background-image: none;
    }

    .ContentPage-ContentAdditionalWrapper {
        margin: 0;
    }

    .ContentPage-ContentAdditionalTitle {
        margin-top: 0;
        text-align: center;
    }

    .ContentPage-AdditionalBar_theme_dark .ContentPage-ContentAdditionalTitle {
        display: none;
    }

    .ContentPage-type_pressa {
        color: #fff;
    }

    .ContentPage-Title:before {
        fill: #fff;
        transform: translateY(-50%);
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 20px 5px 0px 5px;
    }

    .ContentPage_page_text .ContentPage-Wrapper {

    }

}
