.PressContentList {
    background-color: #333;
}

.PressContentList-Item {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(180deg, #2C2C2C, #333);
}

.PressContentList-Wrapper {
    width: 100%;
    margin: auto;
}

.PressContentList-ItemTitle {
    max-width: calc(100% - 40px);
    display: inline-block;
    font-size: 26px;
    line-height: 1;
    padding: 0;
    text-align: start;
    font-family: "Averta Regular", Arial, sans-serif;
    /*max-width: 745px;*/
    color: #fff;
    margin: 24px 24px;
}

.PressContentList-ItemTitle span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 56px;
    padding-bottom: 10px;
}

@media (min-width: 720px) {
    .PressContentList {
        margin-top: 30px;
    }

    .PressContentList-ItemTitle {
        font-size: 36px;
    }

}


@media (min-width: 1024px) {

    .PressContentList-Wrapper {
        width: 1272px;

    }

    .PressContentList-ItemTitle {
        margin-left: 0;
    }
}

