.MusicItemPreview {
    width: 100%;
    box-shadow: 0 12px 36px 0 rgba(0, 0, 0, 0.16);
    padding: 24px;
    margin: 0 auto;
    border-radius: 6px;
    overflow: visible;
    background: #fff;
}

.MusicItemPreview-ImageWrapper {
    aspect-ratio: 1/1;
    width: 100%;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
}

.MusicItemPreview-Image {
    height: 100%;
    width: 100%;
    border-radius: 6px;
}

.MusicItemPreview-Progress {
    position: absolute;
    top: 0;
    background: #FFDE00;
    width: 130px;
    height: 2px;
}

.MusicItemPreview-TrackInfo {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.MusicItemPreview-TrackInfoWrapper {
    position: absolute;
    left: 50%;
    bottom: 12px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    border-radius: 6px;
    width: calc(100% - 24px);
    height: 50px;
    transform: translateX(-50%);
}

.MusicItemPreview-TrackInfoInner {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding-left: 20px;
}

/*.MusicItemPreview-TrackInfoInner:before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    width: 20px;*/
/*    background: linear-gradient(to right, rgba(255, 255, 255, 0.9) 10%, rgba(255, 255, 255, 0.8) 20%, rgba(255, 255, 255, 0.5) 40%, rgba(255, 255, 255, 0.4) 60%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0.2) 80%, rgba(255, 255, 255, 0.1) 100%);*/
/*    z-index: 1;*/
/*    opacity: 0.9;*/
/*}*/


.MusicItemPreview-PlayerState {
    min-width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background: rgba(255, 255, 255, 0.8);*/
}

.MusicItemPreview-TrackName {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 2px;
    width: 100%;
    color: #fff;
}

.MusicItemPreview-TrackNameInner,
.MusicItemPreview-PlayerNameInner,
.MusicItemPreview-NextTrackNameInner,
.MusicItemPreview-NextPlayerNameInner
{
    display: inline-block;
    white-space: nowrap;
    transition: 0.5s;
}

.MusicItemPreview-TrackNameInner.MusicItemPreview-TrackNameInner_isTicker,
.MusicItemPreview-PlayerNameInner.MusicItemPreview-PlayerNameInner_isTicker,
.MusicItemPreview-NextPlayerNameInner.MusicItemPreview-NextPlayerNameInner_isTicker,
.MusicItemPreview-NextTrackNameInner.MusicItemPreview-NextTrackNameInner_isTicker {
    animation: ticker 5s infinite ease-in-out;
}

.MusicItemPreview-PlayerName {
    color: #fff;
    font-size: 12px;
    line-height: normal;
    width: 100%;
}

.MusicItemPreview-NextTrackWrapper {
    margin-top: 12px;
    cursor: pointer;
    min-height: 60px;
}

.MusicItemPreview-NextTrackTitle {
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    color: #969696;
    margin: 0;
}

.MusicItemPreview-NextTrackInfo {
    color: #000;
    padding-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    flex: 1;
}

.MusicItemPreview-NextTrackInfoWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.MusicItemPreview-NextTrackInfoInner {
    display: flex;
    width: 100%;
}

.MusicItemPreview-NextTrackInfoInner.MusicItemPreview-NextTrackInfoInner_hidden {
    display: none;
}

.MusicItemPreview-NextTrackInfoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    flex: 1;
    width: calc(100% - 60px);
    overflow: hidden;
}

.MusicItemPreview-Arrow {
    z-index: 1;
    position: absolute;
    right: 0;
}

.MusicItemPreview-NextTrackPreviewWrapper {
    height: 48px;
    width: 48px;
    border-radius: 6px;
    background-color: grey;
    position: relative;
}

.MusicItemPreview-NextTrackName {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 3px;
    white-space: nowrap;
}

.MusicItemPreview-NextTrackNameInner {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}

.MusicItemPreview-NextPlayerName {
    font-size: 12px;
    line-height: normal;
    white-space: nowrap;
    color: #969696;
}

@keyframes ticker {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-120%, 0);
    }
}

@media (min-width: 601px) {
    .MusicItemPreview {
        max-width: unset;
        width: 100%;
        height: 432px;
    }
    .MusicItemPreview-ImageWrapper {
        height: 304px;
    }
}

@media (min-width: 1200px) {
    .MusicItemPreview {
        height: 400px;
    }
    .MusicItemPreview-ImageWrapper {
        height: 280px;
    }
}
