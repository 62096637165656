.App {
    min-width: 320px;
    /*max-width: 1320px;*/

    /*!*margin: 0 auto;*!*/
    /*min-height: 100vh;*/
}

.App-Wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
