.ContentItemPreview {
    border-radius: 6px;
    box-shadow: 0 12px 36px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
}

.ContentItemPreview-ImageWrap {
    position: relative;
}

.ContentItemPreview-Link {
    padding: 24px;
    background: #fff;
}

.ContentItemPreview_theme_dark .ContentItemPreview-Link{
    background-color: #181818;
}

.ContentItemPreview-Image {
    /*width: calc(100% - 96px);*/
    aspect-ratio: 1/1;
    border-radius: 6px;
}

.ContentItemPreview-Cart {
     position: absolute;
     right: 12px;
     bottom: 12px;
     content: '';
     display: flex;
     width: 36px;
     height: 36px;
     background-color: #FFDE00;
     border-radius: 6px;
     z-index: 5;
}

.ContentItemPreview-Cart > svg {
    margin: auto;
    color: #FF6600
}

.ContentItemPreview-Cart:hover{
    background-color: #FF6600;
}

.ContentItemPreview-Cart:hover > svg{
    color: #FFDE00;
}

.ContentItemPreview-Button {
    position: absolute;
    width: 140px;
    height: 36px;
    bottom: 12px;
    left: 12px;
    z-index: 5;
    background-color: #FF7F0E;
    background-image: url('../Icons/youtube-alt.svg');
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: 6px center;
    color: #fff;
    font-size: 12px;
    line-height: 36px;
    border-radius: 6px;
}

.ContentItemPreview-Button_type_read {
    background-color: #000;
}

.ContentItemPreview-Button:hover {
    background-color: #FF6600;
}

.ContentItemPreview-ButtonText {
    padding: 0 12px 0 36px;
}

/*стили для size_small*/

.ContentItemPreview_size_small {
}

.ContentItemPreview_size_small .ContentItemPreview-Link {
    padding: 12px;
    /*position: relative;*/
}

.ContentItemPreview_size_small .ContentItemPreview-Info {
    display: flex;
    overflow: hidden;
    position: relative;
}

.ContentItemPreview_size_small .ContentItemPreview-ImageWrap {
    width: 30%;
    min-width: 100px;
    min-height: 100px;
    margin-right: 12px;
    position: static;
}

.ContentItemPreview_size_small .ContentItemPreview-Button{
    bottom: 0;
    left: calc(30% + 36px);
    width: 100px;
}

.ContentItemPreview_size_small .ContentItemPreview-Cart{
    right: 0;
    bottom: 0;
}

@media (min-width: 360px) {
    .ContentItemPreview_size_small .ContentItemPreview-Button{
        left: 112px;
        width: 120px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Button_type_read{
        width: 60%;
        display: flex;
        justify-content: center;
        background-position: 30% center;
    }
}


/*.ContentItemPreview_size_small .ContentItemPreview-Image {*/
/*    width: 110px;*/
/*    height: 100px;*/
/*    margin-left: -10px;*/
/*}*/

/*.ContentItemPreview_size_small .ContentItemPreview-Button {*/
/*    left: 120px;*/
/*    bottom: 20px;*/
/*    min-width: 140px;*/
/*}*/

/*.ContentItemPreview_size_small .ContentItemPreview-Cart {*/
/*    bottom: 15px;*/
/*}*/

/*.ContentItemPreview_size_small .ContentDescription-Tags {*/
/*    display: none;*/
/*}*/

/*.ContentItemPreview_size_small .ContentDescription-Title {*/
/*    color: #000;*/
/*    font-size: 12px;*/
/*    font-family: "Averta Regular", sans-serif;*/
/*    font-weight: 400;*/
/*    width: 80%;*/
/*}*/




/*стили для странцы контента*/
.ContentItemPreview_size_common .ContentItemPreview-Info {
    display: flex;
    flex-direction: column;
}

.ContentItemPreview_size_common .ContentItemPreview-ImageWrap {
    order: 1;
}




/*@media (max-width: 800px) {*/

/*    .ContentItemPreview {*/
/*        height: 432px;*/
/*    }*/

/*    .ContentItemPreview-Image {*/
/*        height: 300px;*/
/*    }*/

/*    .ContentItemPreview-ImageWrap {*/
/*        min-height: 300px;*/
/*    }*/

/*    .ContentItemPreview-Button {*/
/*        bottom: 125px;*/
/*        left: 35px;*/
/*    }*/

/*    .ContentItemPreview_size_small {*/
/*        height: 168px;*/
/*    }*/

/*    .ContentItemPreview_size_small .ContentItemPreview-Cart {*/
/*        bottom: 24px;*/
/*    }*/

/*    .ContentItemPreview_size_small .ContentItemPreview-Button {*/
/*        bottom: 24px;*/
/*        left: 135px;*/
/*        width: 45%;*/
/*    }*/

/*    .ContentAdditional .ContentItemPreview_size_big {*/
/*        display: none;*/
/*    }*/

/*    .ContentItemPreview_size_small .ContentItemPreview-ImageWrap,*/
/*    .ContentItemPreview_size_small .ContentItemPreview-Image {*/
/*        width: 130px;*/
/*        height: 120px;*/
/*        min-width: 130px;*/
/*        margin-right: 5px;*/
/*    }*/

/*    .ContentItemPreview-Link {*/
/*        padding: 25px 0 0 0;*/
/*    }*/
/*}*/

/*@media (min-width: 375px) {*/
/*    .ContentItemPreview-ImageWrap {*/
/*        width: 280px;*/
/*        height: 240px;*/
/*    }*/
/*}*/

/*@media (max-width: 600px) {*/

/*    .ContentItemPreview-Link {*/
/*        padding: 20px 0 0 0;*/
/*    }*/

/*    .ContentItemPreview_size_small .ContentItemPreview-Image {*/
/*        width: 110px;*/
/*        height: 100px;*/
/*    }*/

/*    .ContentItemPreview {*/
/*        height: 360px;*/
/*    }*/

/*    .ContentItemPreview-Image {*/
/*        width: 240px;*/
/*        height: 240px;*/
/*    }*/

/*    .ContentItemPreview-ImageWrap {*/
/*        min-height: 240px;*/
/*        width: 240px;*/
/*    }*/

/*    .ContentItemPreview-Button {*/
/*        bottom: 115px;*/
/*        left: 35px;*/
/*    }*/

/*    .ContentItemPreview_size_small {*/
/*        min-width: 280px;*/
/*        width: 280px;*/
/*        height: 140px;*/
/*    }*/

/*    .ContentItemPreview_size_small .ContentItemPreview-Cart {*/
/*        bottom: 15px;*/
/*    }*/

/*    .ContentItemPreview_size_small .ContentItemPreview-Button {*/
/*        left: 120px;*/
/*        bottom: 21px;*/
/*    }*/

/*    .ContentAdditional .ContentItemPreview_size_big {*/
/*        display: none;*/
/*    }*/

/*    .ContentItemPreview_size_small .ContentItemPreview-ImageWrap,*/
/*    .ContentItemPreview_size_small .ContentItemPreview-Image {*/
/*        width: 110px;*/
/*        height: 100px;*/
/*        min-width: 100px;*/
/*        margin-right: 23px;*/

/*    }*/

/*}*/
