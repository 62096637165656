.OfferPage {
    flex-grow: 1;
}

.OfferPage-Text a{
    color: inherit;
    display: inline;
}

.OfferPage-Text {
    flex-grow: 1;
    max-width: 1300px;
    margin: 93px auto 0;
    text-align: justify;
    padding: 24px;
    font-family: "Averta Regular", Arial, sans-serif;
}

.OfferPage-Text h2 {
    font-family: "Averta Regular", Arial, sans-serif;
    text-align: left;
}

.OfferPage-Text li {
    margin-bottom: 10px;
}

.OfferPage-Text div {
    margin-bottom: 10px;
}

@media (min-width: 720px) {
    .OfferPage-Text {
    }
}

@media (max-width: 1200px) {
    .OfferPage-Text {
        margin-top: 70px;
    }
}
