.SubscriptionPage {
    font-family: "Averta Regular", Arial, sans-serif;
    flex-grow: 1;
    display: flex;
}

.SubscriptionPage-Text {
    flex-grow: 1;
    max-width: 1272px;
    margin: 84px 24px 24px;
}

.SubscriptionPage-Text a,
.SubscriptionPage-Text a:hover,
.SubscriptionPage-Text a:active {
    display: inline;
    color: inherit;
}

@media (min-width: 1024px) {
    .SubscriptionPage-Text {
        margin: 84px auto 24px;
    }
}
