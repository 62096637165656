.CategoryItem {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
}

.CategoryItem_ofPage_category {
    padding-bottom: 0;
}

.CategoryItem-Wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1272px;
}

.CategoryItem_theme_blackOrangeGradient .CategoryItem-Wrapper {
     max-width: 100%;
     padding: 0;
}

.MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {
    height: 530px;
}

.CategoryItem-ContentWrap {
    /* width: 100%; */
    max-width: 1440px;
    margin: 0 auto 24px;
    position: relative;
}

.CategoryItem_ofPage_category .CategoryItem-ContentWrap {
    margin: 0 24px 24px;
}

.CategoryItem-HomeLink_ofPage_main {
    display: none;
}

.CategoryItem-Title {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
    color: #fff;
    margin: 24px;
}

.CategoryItem-Title.CategoryItem-type_pressa {
    line-height: 1.5;
}

.CategoryItem_ofPage_category .CategoryItem-Link_ofPage_category .CategoryItem-Title {
    font-family: 'Averta Bold Italic', Arial, sans-serif;
    color: #fff;
}

.CategoryItem-type_pressa{
    color: #fff;
}

.CategoryItem-Title:before {
    fill: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -30px;
    content: '';
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.CategoryPage .CategoryItem-Title::before {
    display: none;
}

/*разные фоны категорий */

/*.CategoryItem_theme_dark,*/
/*.CategoryItem:first-child {*/
/*    padding: 0;*/
/*    background: #3B454E;*/
/*}*/

.CategoryItem {
    background: #EEEEEE;
 }

.CategoryItem_theme_blackOrangeGradient{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 55%, rgba(220, 125, 29, 0.88) 100%),
    linear-gradient(180deg, #0A0A0A 0%, #333333 100%);
}

.CategoryItem_theme_orangeGradient{
    background: radial-gradient(#FFE460, #FF901E);
}

.CategoryItem_theme_orangeYellowGradient{
    background: linear-gradient(180deg, #FF7F0E 0%, #FFA900 49%, #FFDE00 100%);
}

.CategoryItem_theme_yellow{
    background: #FFDE00;
}

.CategoryItem_theme_darkGrey{
    background: linear-gradient(45deg, #19181F 0%, #141420 100%);
}


.CategoryItem-TitleLink {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
}

.CategoryItem_theme_yellow .CategoryItem-Title,
.CategoryItem_theme_lightGrey .CategoryItem-Title {
    color: #000;
}

/*кнопка больше*/

.CategoryItem-MoreButtonLink {
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: "Averta Regular", sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: inherit;
    position: relative;
}

.CategoryItem_theme_light .CategoryItem-MoreButtonLink {
    margin-bottom: 30px;
}


.CategoryItem-MoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 48px;
    margin: 0 auto;
    padding-left: 12px;
    border-radius: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    box-shadow: 0 20px 32px 0 rgba(96, 97, 112, 0.24),
    0 2px 8px 0 rgba(40, 41, 61, 0.08);
}

.CategoryItem-MoreButton img {
    margin-left: 12px;
    margin-top: 3px;
}

.CategoryItem .CategoryItem-MoreButton {
    background-color: #FFA900;
    color: #fff;
}

/*категория на странице категорий */
.CategoryItem_ofPage_category {
    background-color: #f6f6f6;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark {
    background-color: #3B454E;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S {
    /*filter: invert(100%);*/
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-Title {
    color: #ffffff;
}

.CategoryItem-MoreButtonWrapper{
    margin: auto;
}

.CategoryItem-LinkWrap {
    width: 100%;
    max-width: 1440px;
    align-self: center;
    position: relative;
}

/*кнопка домой*/
.CategoryItem-HomeLink_ofPage_category {
    text-decoration: none;
    display: block;
    align-self: center;
    z-index: 1;
}

.CategoryItem-HomeLink-Btn_size_S {
    height: 30px;
    width: 30px;
    background-image: url('../Icons/backBlack.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    position: relative;
}

.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S:nth-child(1) {
    background-image: url('../Icons/back.svg');
}

.MainPage-Content .CategoryItem-CategoryLink {
    height: 30px;
    width: 30px;
    background-image: url('../Icons/arrowMainPage.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 25px;
    margin-left: 10px;
    margin: auto 0;

}

.CategoryItem:first-child .CategoryItem-CategoryLink {
    display: none;
}


@media (min-width: 720px) {
    .CategoryItem-Title  {
        font-size: 36px;
    }
}

/*@media (max-width: 800px) {*/

/*    .MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {*/
/*        height: 468px;*/
/*        width: 100%;*/
/*    }*/

/*    .CategoryItem-ContentWrap {*/
/*        width: 100%;*/
/*        margin-bottom: 30px;*/
/*    }*/

/*    .CategoryItem-LinkWrap {*/
/*        width: 100%;*/
/*    }*/

/*    .CategoryPage .CategoryItem-LinkWrap {*/
/*        padding: 0;*/
/*    }*/

/*    .CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-Title {*/
/*        margin-left: 4px;*/
/*    }*/



/*    .CategoryItem-HomeLink_ofPage_category {*/
/*        margin-left: 12px;*/
/*    }*/

/*    .CategoryPage .CategoryItem-MoreButtonWrapper {*/
/*        display: none;*/
/*    }*/

/*}*/

/*@media (max-width: 600px) {*/

/*    .MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {*/
/*        height: 400px;*/
/*    }*/

/*}*/

@media (min-width: 1024px) {
    .CategoryItem_theme_blackOrangeGradient .CategoryItem-Wrapper {
        max-width: 1272px;
    }

    .CategoryItem-LinkWrap {
        max-width: 1272px;
    }

    .CategoryItem-Title {
        margin-left: 0;
        color: #fff
    }

    .ContentListPartial-ItemsWrapper {
        padding: 0;
    }

    .CategoryItem_ofPage_category .CategoryItem-ContentWrap {
        margin: 0 0 24px;
    }

    .CategoryPage-Content.CategoryPage-Content_type_pressa .CategoryItem-Wrapper{
        max-width: unset;
    }
}

/*@media (min-width: 720px) {*/



/*    .CategoryItem_theme_dark .CategoryItem-MoreButtonLink {*/
/*        bottom: 0;*/
/*    }*/




/*    .CategoryItem-HomeLink-Btn_size_L {*/
/*        display: block;*/
/*        text-transform: lowercase;*/
/*        font-family: "Averta Regular", Arial, sans-serif;*/
/*        font-size: 20px;*/
/*    }*/


/*}*/

/*@media (min-width: 1200px) {*/



/*    .MainPage-Content .CategoryItem-CategoryLink {*/
/*        display: none;*/
/*    }*/

/*    .CategoryPage .CategoryItem-Title::before {*/
/*        display: block;*/

/*    }*/

/*    .CategoryItem-HomeLink.CategoryItem-HomeLink_ofPage_category {*/
/*        display: none;*/
/*    }*/

/*    .CategoryItem-Title:before {*/
/*        display: block;*/
/*        top: 50%;*/
/*    }*/

/*    .CategoryItem-HomeLink-Btn_size_S {*/
/*        display: none;*/
/*    }*/

/*    .CategoryItem-MoreButton {*/
/*        max-width: 180px;*/
/*        height: 40px;*/
/*        font-size: 14px;*/
/*    }*/

/*}*/
