@keyframes blinkAnimation {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 0.1; }
}

.UserPanel-Container {
  display: flex;
  flex-flow: column nowrap;
  margin: 24px;
  padding: 12px;
  border-radius: 6px;
  background: #393652;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  gap: 12px;
}

.UserPanel-Field {
  display: flex;
  flex-flow: column nowrap;
}

.UserPanel-FieldLabel {
  font-size: 12px;
  line-height: 2;
}

.UserPanel-FieldInput {
  font-size: 14px;
  line-height: 1.7;
}

.UserPanel-Services {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}

.UserPanel-Service {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  gap: 12px;
  width: calc(50% - 8px);
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.UserPanel-Service_active {
  color: #ff6600;
}

.UserPanel-Service_disabled {
  animation: blinkAnimation 1.5s infinite;
  cursor: default;
}

.UserPanel-ServiceCheckbox {
  position: relative;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #fff;
}

.UserPanel-Service_active .UserPanel-ServiceCheckbox {
  border-color: #ff6600;
}

.UserPanel-Service_active .UserPanel-ServiceCheckbox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 3px;
  width: 4px;
  height: 7px;
  border-right: 2px solid #FF6600;
  border-bottom: 2px solid #FF6600;
  transform: rotate(45deg);
}

.UserPanel-ServiceLabel {
  flex-grow: 1;
  text-align: left;
}

.UserPanel-Confirm {
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  width: 100%;
  order: 1;
}

.UserPanel-ConfirmTitle {
  font-size: 12px;
  line-height: 1.2;
}

.UserPanel-ConfirmButtons {
  display: flex;
  flex-flow: row-reverse nowrap;
  gap: 12px;
}

.UserPanel-ConfirmButtons.UserPanel-ConfirmButtons_active {
  flex-flow: row nowrap;
}

.UserPanel-ConfirmButton {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background: #969696;
  width: 50%;
  min-height: 44px;
  border: none;
  border-radius: 6px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.UserPanel-ConfirmButton.UserPanel-ConfirmButton_active {
  background: #ffa900;
}

.UserPanel-ServiceError {
  font-size: 12px;
  line-height: 1.2;
  color: #ff0000;
  order: 1;
}

@media (min-width: 1200px) {
  .UserPanel-Services {
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
  }

  .UserPanel-Service {
    flex-flow: row-reverse nowrap;
    width: 100%;
    font-size: 12px;
  }

  .UserPanel-Confirm {
    order: 0;
  }

  .UserPanel-ConfirmButton {
    font-size: 12px;
    font-weight: 400;
    min-height: 27px;
  }

  .UserPanel-ServiceError {
    font-size: 12px;
    line-height: 1.2;
    color: #ff0000;
    order: 0;
  }
}
