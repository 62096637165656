.MainPage {
}

.MainPage-Content {
    margin-top: 84px;
}

@media (max-width: 1200px) {
    .MainPage-Content {
    }
}
