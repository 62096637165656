.PressContentItem {
    text-decoration: none;
    color: inherit;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.PressContentItem_isSlide {
    padding: 0 12px;
}

.PressContentItem-ImageWrap {
    width: 100%;
    aspect-ratio: 0.765;
    height: auto;
    position: relative;
    overflow: hidden;
    display: inline-block;
    border-radius: 5px;
}

.PressContentSlider .swiper-slide-active .PressContentItem-ImageWrap {
    box-shadow: 0 12px 36px 0 rgba(255, 169, 0, 0.4);
}

.swiper-container {
    max-width: 1600px;
}

.PressContentItem-Image {
    border-radius: 2px;
    position: absolute;
    display: block;
    width: 100%;
    /*height: 262px;*/
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.PressContentItem-Title {
    text-align: center;
    margin: 12px 0;
    position: relative;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
    color: #fff;
}

.PressContentSlider .swiper-slide-active .PressContentItem-Title {
    color: #FF6600;
}

.PressContentItem-Cart {
    position: absolute;
    right: 12px;
    bottom: 12px;
    content: '';
    display: flex;
    width: 36px;
    height: 36px;
    background-color: #FFDE00;
    border-radius: 6px;
    z-index: 5;
}

.PressContentItem-Cart > svg {
    margin: auto;
    color: #FF6600
}

.PressContentSlider .swiper-slide-active .PressContentItem-Cart{
    background-color: #FF6600;
}

.PressContentSlider .swiper-slide-active .PressContentItem-Cart > svg{
    color: #FFDE00;
}

@media (max-width: 800px) {
    .PressContentItem {
        /* width: 144px !important; */
        /* margin-right: 10px !important; */
    }

    .swiper-container {
        /* width: 100%; */
    }

    /*.PressContentItem-Image,*/
    /*.PressContentItem-ImageWrap {*/
    /*    width: 144px;*/
    /*    height: 188px;*/
    /*}*/
}

@media (max-width: 600px) {

    .PressContentItem {
        /* width: 130px !important; */
    }

    /*.PressContentItem-Image,*/
    /*.PressContentItem-ImageWrap {*/
    /*    width: 130px;*/
    /*    height: 170px;*/
    /*}*/
}


@media (min-width: 1024px) {

    .PressContentItem-ImageWrap {
        height: 262px;
        width: 200px;
    }

    .PressContentItem .PressContentItem-ImageWrap:before {
        width: 35px;
        height: 35px;
    }

    .PressContentSlider .swiper-slide-active .PressContentItem-ImageWrap {
        /*box-shadow: 0 12px 36px 0 rgba(255, 169, 0, 0.4);*/
        box-shadow: none;
        transition: all ease-in 0.3s;
    }

    .PressContentSlider .swiper-slide:hover .PressContentItem-ImageWrap {
        box-shadow: 0 12px 36px 0 rgba(255, 169, 0, 0.4);
        transform: scale(1.03);
        transition: all ease-in 0.3s;
    }

    .PressContentSlider .swiper-slide-active .PressContentItem-Cart{
        background-color: #FFDE00;
        transition: all ease-in 0.3s;
    }

    .PressContentSlider .swiper-slide-active .PressContentItem-Cart > svg{
        color: #FF6600;
        transition: all ease-in 0.3s;
    }

    .PressContentSlider .swiper-slide:hover .PressContentItem-Cart{
        background-color: #FF6600;
        transition: all ease-in 0.3s;
    }

    .PressContentSlider .swiper-slide:hover .PressContentItem-Cart > svg{
        color: #FFDE00;
        transition: all ease-in 0.3s;
    }

    .PressContentSlider .swiper-slide-active .PressContentItem-Title {
        color: #FFF;
        transition: all ease-in 0.3s;
    }
    .PressContentSlider .swiper-slide:hover .PressContentItem-Title {
        color: #FF6600;
        transition: all ease-in 0.3s;
    }
}

