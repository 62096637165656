.Header,
.Header_visible.Header_ofPage_CategoryPage,
.Header_visible.Header_ofPage_ContentPage {
    background-color: #000000;
    position: fixed;
    z-index: 99;
    width: 100%;
    /*height: 93px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 84px;
    transition: background-color ease-in 0.5s;
}

.Header_ofPage_CategoryPage {
    background-color: rgba(0, 0, 0, 0.6);
    transition: background-color ease-in 0.2s;
}

.Header_ofPage_ContentPage {
    background-color: #fff;
    transition: background-color ease-in 0.2s;
}

.Header-Wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    width: 100%;
    max-width: 1272px;
}

.Header-LogoContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;
    margin-left: 24px;
}

.Header-Logo {
    max-height: 36px;
}

.Header-LogoLink {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Header-ButtonWrapper {
    position: relative;
    height: 84px;
    width: 50px;
    margin-right: 24px;
}

.Header-Select {
    cursor: pointer;
    position: relative;
    margin-left: auto;
    width: 50px;
}

.Header-SelectList {
   display: none;
    padding: 0;
    list-style-type: none;
}

.Header-SelectList_visible {
    position: absolute;
    top: 100%;
    display: block;
    margin: 0;
    background-color: #fff;
    width: 40px;
}

.Header-SelectItem {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 30px 10px 0;
    width: 60px;
}

.Header-SelectItem span {
    font-size: 16px;
}

.Header-SelectItem_state_selected {
    background-image: url("../Icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 30px 13px;
    background-size: 10px;
}

.Header-SelectItem_active {
    display: none;
}


.Header-HamburgerButton,
.Header-CloseButton {
    display: block;
    position: absolute;
    padding: 25px 0 25px 25px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 10;
    opacity: 0;
    transition:  all cubic-bezier(0.17,0.67,1,-0.1) 0.5s;
}

.Header_ofPage_ContentPage .Header-HamburgerButton {
    filter: invert(30%);
}

.Header-HamburgerButton_visible,
.Header-CloseButton_visible {
    opacity: 1;
}

.Header-Menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    min-height: 100%;
    z-index: 1;
    transform: translateX(-100%);
    opacity: 0;
    transition: opacity ease-in-out 0.8s, transform ease-in-out 0.2s;
}

.Header-Menu_visible {
    transform: translateX(0);
    opacity: 1;
    width: 100%;
}

/*@media (max-width: 1200px) {*/
/*    .Header-Wrapper {*/
/*        width: 100%;*/
/*    }*/
/*    .Header {*/
/*        height: 70px;*/
/*    }*/
/*}*/

.Header-UserPanelWrapper {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 42px;
}
.Header-UserPanelButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.Header-UserPanelIcon {
    width: 20px;
    height: 20px;
    color: #fff;
}
.Header-UserPanelIcon.Header-UserPanelIcon_open {
    color: #ff6600;
}
.Header-UserPanel {
    position: absolute;
    top: calc(100% + 12px);
    right: 0;
    margin: 0;
    width: 170px;
    z-index: 10;
}

@media (min-width: 1200px) {
    .Header-Menu,
    .Header-Menu_visible {
        display: flex;
        justify-content: flex-end;
        position: relative;
        transform: translateX(0);
        height: 84px;
        top: auto;
        bottom: auto;
        left: 0px;
        opacity: 1;
    }

    .Header-ButtonWrapper {
        display: none;
    }

    .Header-LogoContainer {
        margin-left: 0;
    }
}
