.PressContentSlider-ContentItem {

}

.PressContentSlider .swiper-button-prev,
.PressContentSlider .swiper-button-next {
    display: none;
    background-color: #fff;
    background-size: 50%;
    transform-style: preserve-3d;
    z-index: 100000000;
}
.PressContentSlider .swiper-button-next.swiper-button-disabled,
.PressContentSlider .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
}

/*.PressContentSlider .swiper-button-prev::before,*/
/*.PressContentSlider .swiper-button-next::before {*/
/*    display: block;*/
/*    content: '';*/
/*    width: 500px;*/
/*    height: 350px;*/
/*    background: linear-gradient(90deg, #3B454E 0%, rgba(59, 69, 78, 0) 70%);*/
/*    transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);*/
/*    pointer-events: none;*/
/*    position: absolute;*/
/*    bottom: -130px;*/
/*    right: -100px;*/
/*}*/

.PressContentSlider-blackout-next {
     transform: matrix(1, 0, 0, 1, 0, 0);
     right: 20px;
}

.PressContentSlider .swiper-slide img {
    filter: grayscale(100%);
}

.PressContentSlider .swiper-slide-active img {
    filter: none;
}

.PressContentSlider .swiper-container {
    padding-bottom: 20px;
    margin-bottom: 24px;
}


.PressContentSlider .swiper-pagination {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PressContentSlider .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #FFA900;
    opacity: 1;
}

.PressContentSlider .swiper-pagination-bullet-active {
    background-color: transparent;
    width: 15px;
    height: 15px;
    border: 3px solid #FFA900;
}

/*.swiper-slide {*/
/*    width: auto !important;*/
/*    margin-right: 10px !important;*/
/*}*/

.PressContentPage .swiper-slide {
    width: 100% !important;
}

@media (min-width: 1200px) {

}

@media (min-width: 1200px) {
    .PressContentSlider {
        max-width: 1272px;
        margin: 0 auto;
        padding-top: 20px;
    }

    .PressContentSlider .swiper-wrapper {
        padding-top: 20px;
    }

    .PressContentSlider .swiper-slide-active img {
        filter: grayscale(100%);
        transition: all ease-in 0.3s;
        box-shadow: none;
    }

    .PressContentSlider .swiper-slide:hover img {
        filter: none;
        transition: all ease-in 0.3s;
    }

    .PressContentSlider .swiper-pagination {
        display: none;
    }

    .swiper-button-prev::before,
    .swiper-button-next::before {
        display: block;
    }

    .PressContentSlider .swiper-button-prev,
    .PressContentSlider .swiper-button-next {
        display: block;
        transform-style: preserve-3d;

    }
    .CategoryPage-Content .swiper-button-prev {
        background: transparent;
    }
    .CategoryPage-Content .swiper-button-prev::before,
    .CategoryPage-Content .swiper-button-next::before {
        height: 320px;
        bottom: -130px;
        background: linear-gradient(90deg, #3B454E 0%, rgba(59, 69, 78, 0) 100%);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);

    }

}
