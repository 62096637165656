.Player {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 48px;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
    padding: 0 5px 0 20px;
    z-index: 5;
    overflow: hidden;
}

.Player-Inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

.Player-Preview {
    height: 28px;
    min-width: 28px;
    margin-right: 10px;
    border-radius: 4px;
}

.Player-PrevTrack, .Player-NextTrack, .Player-Play {
    border: none;
    background-color: inherit;
    outline: none;
}

.Player-PrevTrack:focus, .Player-NextTrack:focus, .Player-Play:focus {
    outline: none;
}

.Player-PrevTrack {
    width: 12px;
    height: 10px;
    background-image: url('../Icons/prevTrack.svg');
    background-repeat: no-repeat;
    background-position: center center;
}

.Player-NextTrack {
    width: 12px;
    height: 10px;
    background-image: url('../Icons/nextTrack.svg');
    background-repeat: no-repeat;
    background-position: center center;
}

.Player-Play {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Player-Controls {
    min-width: 75px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px 0 5px;
}

.Player-TrackInfoInner {
    padding: 10px;
    height: 100%;
    overflow: hidden;
    position: relative;
    flex: 1;
}

.Player-TrackInfoInner:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 15%;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0.1) 100%);
    opacity: 0.9;
}

.Player-TrackName {
    font-size: 14px;
    margin-bottom: 5px;
    width: 100%;
}

.Player-PlayerName {
    font-size: 12px;
    color: #717171;
    width: 100%;
}

.Player-TrackNameInner, .Player-PlayerNameInner {
    display: inline-block;
    white-space: nowrap;
    transition: 0.5s;
}

.Player-TrackNameInner.Player-TrackNameInner_isTicker,
.Player-PlayerNameInner.Player-PlayerNameInner_isTicker{
    animation: tickerPlayer 5s infinite ease-in-out;
}

@keyframes equalizerPlay1 {
    0% {
        height: 8px;
    }
    25% {
        height: 12px;
    }
    50% {
        height: 16px;
    }
    75% {
        height: 12px;
    }
    100% {
        height: 8px;
    }
}

@keyframes equalizerPlay2 {
    0% {
        height: 16px;
    }
    25% {
        height: 12px;
    }
    50% {
        height: 8px;
    }
    75% {
        height: 12px;
    }
    100% {
        height: 16px;
    }
}

@keyframes tickerPlayer {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-120%, 0);
    }
}
